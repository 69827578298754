import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import ArrowBorder from "../atoms/ArrowBorder"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"
import LinebreakText from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/LinebreakText"

const ProductCard = ({ item }) => {
  const hoverColor = JSON.parse(item.content).background_color

  const bgTypes = {
    "green-paper": "group-hover:bg-rapps-green",
    "blue-paper": "group-hover:bg-rapps-blue",
    "purple-paper": "group-hover:bg-rapps-purple",
    "red-paper": "group-hover:bg-rapps-red",
    "orange-paper": "group-hover:bg-rapps-orange",
    "yellow-paper": "group-hover:bg-rapps-yellow",
    nature: "",
  }

  return (
    <Link
      className="relative block w-full h-full p-6 bg-bottom group "
      link={item.fields.full_slug_prepared}
    >
      <div
        className={`absolute inset-x-0 z-[0] bottom-0 h-3/5 used-mask-1 bg-paper-mix ${bgTypes[hoverColor]}`}
      ></div>
      <div className="relative z-20 flex flex-col justify-between h-full text-center">
        <div>
          <Image
            className="object-contain w-full mb-4 transition duration-300 origin-bottom transform h-72 group-hover:scale-105"
            image={item.main_bundle_url?.length > 0 ? item.main_bundle_url : ""}
          />
          <span className="block mb-4 text-xl text-secondary">
            <LinebreakText text={item.field_headline_string} />
          </span>
        </div>
        <span className="font-bold text-primary">
          <ArrowBorder className="ml-2 inline-block w-8 h-8 -mb-1.5" />
        </span>
      </div>
    </Link>
  )
}

const ProductCards = ({ block }) => {
  let view = block.view?.length > 0 ? block.view : "grid"
  let showSlides = parseInt(
    block.show_slides && block.show_slides !== "" ? block.show_slides : 4
  )
  if (block.card_items?.length > 0) {
    return (
      <BlockWrapper blockWidth="container" showHeadline="false" block={block}>
        <HeadlineEnhanced
          className="text-primary"
          kicker={block.kicker}
          headline={block.headline}
          headlineLevel={block.headline_level}
          subheadline={block.subheadline}
        />
        {view === "grid" ? (
          <GridWrapper showSlides={showSlides}>
            {block.card_items?.length > 0 &&
              block.card_items.map((item, index) => (
                <div
                  className={`block w-full mt-8 sm:w-1/2 md:w-1/3 lg:w-1/${showSlides} px-grid`}
                  key={index}
                >
                  <ProductCard item={item} />
                </div>
              ))}
          </GridWrapper>
        ) : (
          <SliderWrapper showSlides={showSlides}>
            {block.card_items?.length > 0 &&
              block.card_items.map((item, index) => (
                <div className="block h-full" key={index}>
                  <ProductCard item={item} />
                </div>
              ))}
          </SliderWrapper>
        )}
      </BlockWrapper>
    )
  } else {
    return null
  }
}

export default ProductCards
