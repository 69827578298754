import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

import Hero from "../../src/components/modules/Hero"
import ProductCards from "../../src/components/modules/ProductCards"

const ProductLandingPage = ({ content, breadcrumbs, products }) => {
  return (
    <Layout>
      <SEO content={content} og_image={content?.image?.filename} />
      <Hero
        block={{
          headline: content.headline,
          subheadline: content.subheadline,
          image: content.image,
        }}
      />
      <ProductCards
        block={{
          card_items: products,
        }}
      />

      <BodyBlocks body={content.body} />

      {/* <h3>Produkte</h3>
      <pre>{JSON.stringify(products, null, 2)}</pre> */}
    </Layout>
  )
}

export default ProductLandingPage
