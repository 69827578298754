import React from "react"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const Hero = ({ block }) => {
  return (
    <section
      className={`relative z-10 ${
        block.image?.filename?.length > 0 ? "h-hero-desktop -mt-4" : ""
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="my-8 block-w-container">
          <HeadlineEnhanced
            className="text-center"
            headline={block.headline}
            subheadline={block.subheadline}
          />
        </div>
        {block.image?.filename?.length > 0 && (
          <div className="flex-auto overflow-hidden used-mask-y-1">
            <Image
              className="object-cover w-full h-full max-h-full "
              image={block.image}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
